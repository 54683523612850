import { CodeSurfer, CodeSurferColumns, Step } from 'code-surfer';
import { Image, Invert, Notes, Split, SplitRight, Appear, Head } from 'mdx-deck';
import { github, dracula } from '@code-surfer/themes';
import customTheme from "../../../../../../custom-theme";
import ImageLayout from "../../../../../../image-split-layout";
import * as imgs from "../../../../../../images";
import VideoUserMedia from "../../../../../../video-user-media";
import AudioUserMedia from "../../../../../../audio-user-media";
import InputDemo from "../../../../../../inputer";
import Peers from "../../../../../../peerjs";
import * as React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  Image: Image,
  Invert: Invert,
  Notes: Notes,
  Split: Split,
  SplitRight: SplitRight,
  Appear: Appear,
  Head: Head,
  github: github,
  dracula: dracula,
  customTheme: customTheme,
  ImageLayout: ImageLayout,
  imgs: imgs,
  VideoUserMedia: VideoUserMedia,
  AudioUserMedia: AudioUserMedia,
  InputDemo: InputDemo,
  Peers: Peers,
  React: React
};